import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
  useNavigate,
} from "react-router-dom";
import "./App.css";

import { FiPhone } from "react-icons/fi";

// Pages
import Home from "./pages/home/Home";
import About from "./pages/about/About";
import PatientCare from "./pages/patientCare/PatientCare";
import Departments from "./pages/departments/Departments";
import AcademicsAndResearch from "./pages/academic&research/AcademicsAndResearch";
import NewsAndMedia from "./pages/news&media/NewsAndMedia";
import Careers from "./pages/careers/Careers";
import CheckUp from "./pages/PremitiveCheckUpDirectory/CheckUp";
import ContactUs from "./pages/contactus/ContactUs";
import Adminstration from "./pages/Administration/Adminstration";
import DoctorsTalk from "./pages/doctorsTalk/doctorsTalk";
import Doctor from "./pages/doctor/Doctor";
import IndividualNews from "./pages/news&media/IndividualNews";
import IndividualCareer from "./pages/careers/IndividualCareer";
import Department from "./pages/department/Department";
import PrivacyPolicy from "./pages/PrivacyPolicy/PrivacyPolicy";
import BookAppointment from "./pages/bookAppointment/BookAppointment";
import Gallery from "./pages/gallery/Gallery";
import IndividualGallery from "./pages/gallery/IndividualGallery";
import ENT from "./pages/department/ENT";
import IndividualBookAppointment from "./pages/bookAppointment/IndividualBookAppointment";
import RadiologyFellowship from "./pages/fellowship/RadiologyFellowship";

// Components
import Navbar from "./components/NavBar/NavBar";
import TopBar from "./components/TopBar/TopBar";
import { Container } from "@mui/material";
import Footer from "./components/footer/Footer";
import FamilyClinic from "./pages/FamilyClinic/FamilyClinic";
import FamilyClinicNavbar from "./components/FamilyClinicNavbar/FamilyClinicNavbar";
import FamilyClinicFooter from "./components/FamilyClinicFooter/FamilyClinicFooter";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import HealthAcademy from "./pages/healthAcademy/HealthAcademy";

function App() {
  const [isLargeScreen, setIsLargeScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth >= 1000); // Adjust the breakpoint as needed
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Router>
      <MainContent isLargeScreen={isLargeScreen} />
    </Router>
  );
}

function MainContent({ isLargeScreen }) {
  const location = useLocation();
  const isFamilyClinicRoute = location.pathname.startsWith("/familyclinic");

  function ScrollToTop() {
    useEffect(() => {
      window.scrollTo(0, 0);
    }, [location]);

    return null;
  }

  return (
    <>
      <Container maxWidth={false} style={{ width: "95%" }}>
        {isFamilyClinicRoute ? <FamilyClinicNavbar /> : <Navbar />}
      </Container>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path="/administration/:name" element={<Adminstration />} />
        <Route path="/doctor/:name" element={<Doctor />} />
        <Route path="/patientcare" element={<PatientCare />} />
        <Route path="/departments" element={<Departments />} />
        <Route exact path="/departments/ENT" element={<ENT />} />
        <Route path="/departments/:department" element={<Department />} />
        <Route
          path="/departments/preventivecheckupdirectory"
          element={<CheckUp />}
        />
        <Route
          path="/academicsandresearch"
          element={<AcademicsAndResearch />}
        />
        <Route path="/newsandmedia" element={<NewsAndMedia />} />
        <Route path="/newsandmedia/:heading" element={<IndividualNews />} />
        <Route path="/careers" element={<Careers />} />
        <Route path="/careers/:id" element={<IndividualCareer />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/doctor'stalk" element={<DoctorsTalk />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/bookappointment" element={<BookAppointment />} />
        <Route
          path="bookappointment/:id"
          element={<IndividualBookAppointment />}
        />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/gallery/:heading" element={<IndividualGallery />} />
        <Route
          path="/academicsandresearch/radiologyfellowship"
          element={<RadiologyFellowship />}
        />
        <Route path="/familyclinic/home" element={<FamilyClinic />} />
        <Route path="/healthacademy" element={<HealthAcademy />} />
      </Routes>
      <BookHealthCheckup />
      <FamilyClinictLink />
      <HealthAcademyLink />
      {!isFamilyClinicRoute && (
        <ConditionalBookAppointmentLink isLargeScreen={isLargeScreen} />
      )}{" "}
      {isFamilyClinicRoute ? <FamilyClinicFooter /> : <Footer />}
    </>
  );
}

function ConditionalBookAppointmentLink({ isLargeScreen }) {
  const location = useLocation();

  return (
    (isLargeScreen || location.pathname === "/") && (
      <div>
        <a
          href="https://bookmyopticket.com/hospital/hospital_detail1.php?hospital_id=57&hospital=GG+Hospital"
          target="_blank"
          rel="noopener noreferrer">
          <div className="appointment">
            <Typography fontSize="15px" fontFamily="Inter" fontWeight="600">
              Book Appointment
            </Typography>
          </div>
        </a>
      </div>
    )
  );
}

function FamilyClinictLink() {
  const location = useLocation();
  const navigate = useNavigate();

  const handleClickFamilyClinic = () => {
    navigate("/familyclinic/home");
  };

  return (
    location.pathname === "/" && (
      <div>
        <div onClick={handleClickFamilyClinic} style={{ cursor: "pointer" }}>
          <div className="familyclinic">
            <Typography fontSize="15px" fontFamily="Inter" fontWeight="600">
              GG Family Clinic
            </Typography>
          </div>
        </div>
      </div>
    )
  );
}

function BookHealthCheckup() {
  const location = useLocation();
  const navigate = useNavigate();

  return (
    location.pathname === "/" && (
      <div>
        <div style={{ cursor: "pointer" }}>
          <div className="checkup">
            <a href="tel:+91 8714866647" style={{ textDecoration: "none" }}>
              <Button
                variant="contained"
                startIcon={<FiPhone />}
                style={{ backgroundColor: "#14609c", color: "white" }}
                sx={{
                  borderRadius: "7px",
                  textTransform: "none",
                  //   width: "144px",
                  height: "45px",
                  boxShadow: "none",
                  fontWeight: "500",
                }}>
                Book Health-Checkup
              </Button>
            </a>
          </div>
        </div>
      </div>
    )
  );
}

function HealthAcademyLink() {
  const location = useLocation();
  const navigate = useNavigate();

  const handleHealthAcademyLink = () => {
    navigate("/healthacademy");
  };

  return (
    location.pathname === "/academicsandresearch" && (
      <div>
        <div onClick={handleHealthAcademyLink} style={{ cursor: "pointer" }}>
          <div className="healthAcademy">
            <Typography fontSize="15px" fontFamily="Inter" fontWeight="600">
              GG Health Academy
            </Typography>
          </div>
        </div>
      </div>
    )
  );
}

export default App;
