// src/AcademyInfo.js
import React from "react";
import { Container, Typography, Grid, Card, CardContent } from "@mui/material";
import styles from "./HealthAcademy.module.css";

import healthacademylogo from "../../assets/images/health-academy-logo.png";

const HealthAcademy = () => {
  return (
    <Container maxWidth="xl" className={styles.container}>
      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}>
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}>
          <img src={healthacademylogo} alt="" width="35%" height="35%" />
        </Grid>
        <Grid item xs={12}>
          <Typography
            className={styles.heading}
            gutterBottom
            color="var(--dark, #211E1E)"
            fontFamily="Inter"
            fontSize="45px"
            fontStyle="normal"
            fontWeight="600"
            sx={{
              fontSize: {
                xs: "27px",
                sm: "29px",
                md: "45px",
              },
            }}>
            GG Health Academy
          </Typography>
        </Grid>
      </Grid>

      <Typography
        color="var(--dark, #211E1E)"
        fontFamily="Inter"
        sx={{
          fontSize: {
            xs: "15px",
            sm: "15px",
            md: "18px",
          },
        }}
        fontStyle="normal"
        fontWeight="400"
        textAlign="justify">
        <span style={{ fontWeight: "600" }}> GG Health Academy</span> started in
        the year 2023 is a modern day healthcare ed-tech company focused on
        professional education for both clinical and non-clinical functions.
        Against the backdrop of a healthcare brand <n></n>
        <span style={{ fontWeight: "600" }}>
          GG HOSPITAL , A Corporate sister concern of Sree Gokulam Health care
          institutions
        </span>{" "}
        <n></n>
        and under the visionary leadership of <n></n>
        <span style={{ fontWeight: "600" }}>
          Dr.Sheeja G Manoj, Managing Director, GG Health Academy
        </span>
        <n></n> has forged the right partnerships for both content and delivery
        to ensure an industry- first experience in the domains of online and
        blended learning. Academy draws from the
        <n></n> <span style={{ fontWeight: "600" }}>GG HOSPITAL </span>pool for
        industry veterans and healthcare specialist to contribute to the
        learning and development of the next in line. Academy aims for a
        transformation that changes lives, businesses, and nations through
        digital up skilling and collaboration to nurture a dynamic skill set
        capable of facing the challenges of tomorrow.
      </Typography>

      <Grid container sx={{ marginTop: "50px" }}>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",

            alignItems: "center",
          }}>
          <Typography
            fontFamily="Inter"
            fontWeight="600"
            sx={{
              fontSize: {
                xs: "24px",
                sm: "24px",
                md: "28px",
              },
            }}>
            Our Vision
          </Typography>
          <Typography
            color="var(--dark, #211E1E)"
            fontFamily="Inter"
            sx={{
              fontSize: {
                xs: "14px",
                sm: "14px",
                md: "16px",
              },
            }}
            fontStyle="normal"
            fontWeight="400"
            textAlign="center">
            To Empower health care journey
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}>
          <Typography
            fontFamily="Inter"
            sx={{
              fontSize: {
                xs: "24px",
                sm: "24px",
                md: "28px",
              },
            }}
            fontWeight="600">
            Mission
          </Typography>
          <Typography
            color="var(--dark, #211E1E)"
            fontFamily="Inter"
            sx={{
              fontSize: {
                xs: "14px",
                sm: "14px",
                md: "16px",
              },
            }}
            fontStyle="normal"
            fontWeight="400"
            textAlign="center">
            Health Care Academy offers continuous education for healthcare
            professionals to help focus on deepening expertise and improving
            patient care.
          </Typography>
        </Grid>
      </Grid>

      <Typography
        fontFamily="Inter"
        fontWeight="600"
        textAlign="center"
        marginTop="50px"
        sx={{
          fontSize: {
            xs: "24px",
            sm: "24px",
            md: "28px",
          },
        }}>
        Courses Offered
      </Typography>
      <Typography
        fontFamily="Inter"
        fontSize="16px"
        sx={{
          fontSize: {
            xs: "14px",
            sm: "14px",
            md: "16px",
          },
          marginTop: {
            xs: "14px",
            sm: "14px",
            md: "18px",
          },
        }}
        fontStyle="normal"
        fontWeight="400"
        textAlign="center"
        marginBottom="20px">
        In collaboration with
        <n></n> <span style={{ fontWeight: "600" }}> GOVT OF KERALA </span>skill
        development programs we are planning to start
        <span style={{ fontWeight: "600" }}>
          {" "}
          <n></n>
          HOSPITAL ADMINISTRATION course <n></n>
        </span>
        in Hybrid mode so as to prepare graduates and under graduates for
        consultancy &amp; managerial position in hospitals and healthcare
        organizations by imparting basic knowledge&amp; skills of modern
        hospital management.
      </Typography>

      <Grid container spacing={3} sx={{}}>
        <Grid item xs={12} md={6}>
          <Typography
            fontFamily="Inter"
            fontWeight="600"
            textAlign="justify"
            sx={{
              marginTop: {
                xs: "5px",
                sm: "5px",
                md: "30px",
              },
              fontSize: {
                xs: "16px",
                sm: "18px",
                md: "20px",
              },
            }}>
            GG INSTITUTE OF PARAMEDICAL SCIENCES
          </Typography>
          <Typography
            fontFamily="Inter"
            sx={{
              fontSize: {
                xs: "13px",
                sm: "13px",
                md: "15px",
              },
            }}
            fontStyle="normal"
            fontWeight="400"
            textAlign="justify"
            marginBottom="10px"
            marginTop="10px">
            GGIPMS under GG Health academy is offering competency training
            programs for clinical skill development and BSS approved skill
            programs in health care.
          </Typography>{" "}
          <Grid container>
            <Grid item xs={6}>
              <Typography
                fontFamily="Inter"
                sx={{
                  fontSize: {
                    xs: "13px",
                    sm: "13px",
                    md: "15px",
                  },
                }}
                fontStyle="normal"
                fontWeight="400"
                textAlign="justify">
                <span style={{ fontWeight: "600" }}> Eligibility:</span> SSLC
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                fontFamily="Inter"
                sx={{
                  fontSize: {
                    xs: "13px",
                    sm: "13px",
                    md: "15px",
                  },
                }}
                fontStyle="normal"
                fontWeight="400"
                textAlign="justify">
                <span style={{ fontWeight: "600" }}>Duration:</span> One year
              </Typography>
            </Grid>
          </Grid>
          <Card className={styles.card}>
            <CardContent>
              <Typography
                fontFamily="Inter"
                fontSize="20px"
                fontWeight="600"
                textAlign="center">
                Diploma Courses
              </Typography>
              <Typography
                fontFamily="Inter"
                fontSize="12px"
                fontWeight="400"
                textAlign="center">
                (Under BSS Syllabus,National Developmeny agency,Govt of India)
              </Typography>
              <ul className={styles.list}>
                {[
                  "Endoscopy Technician",
                  "CSSD Technician",
                  "Operation Theatre Technician",
                  "Medical Lab Technician",
                  "X-ray Technician",
                  "Patient Care Assistant",
                  "ECG Technician",
                  "Dialysis Technician",
                  "Cath Lab Technician Assistant",
                  "Physiotherapy Technician",
                  "CT Scan Technician",
                ].map((item) => (
                  <li key={item} className={styles.listItem}>
                    {item}
                  </li>
                ))}
              </ul>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={6}>
          <Typography
            fontFamily="Inter"
            sx={{
              marginTop: {
                xs: "5px",
                sm: "5px",
                md: "30px",
              },
              fontSize: {
                xs: "16px",
                sm: "18px",
                md: "20px",
              },
            }}
            fontWeight="600"
            textAlign="justify"
            marginTop="50px">
            COMPETENCY TRAINING-CERTIFICATE COURSES
          </Typography>
          <Typography
            fontFamily="Inter"
            sx={{
              fontSize: {
                xs: "13px",
                sm: "13px",
                md: "15px",
              },
            }}
            fontStyle="normal"
            fontWeight="400"
            textAlign="justify"
            marginBottom="10px"
            marginTop="10px">
            Comprehensive competency training certificate courses prepares and
            equip the fresh graduates with competency required for practical
            expertise in their field of activity by providing them with both
            practical and theory aspects on the most recent procedures and
            processes involved in their area of traning. Also provide training
            to experienced nurses Gap training to provide skill competency in a
            specific clinical area.
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography
                fontFamily="Inter"
                sx={{
                  fontSize: {
                    xs: "13px",
                    sm: "13px",
                    md: "15px",
                  },
                }}
                fontStyle="normal"
                fontWeight="400"
                textAlign="justify">
                <span style={{ fontWeight: "600" }}> Affiliation: </span>
                GG-Hospital
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                fontFamily="Inter"
                sx={{
                  fontSize: {
                    xs: "13px",
                    sm: "13px",
                    md: "15px",
                  },
                }}
                fontStyle="normal"
                fontWeight="400"
                textAlign="justify">
                <span style={{ fontWeight: "600" }}> Course duration:</span> 6
                months
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography
                fontFamily="Inter"
                sx={{
                  fontSize: {
                    xs: "13px",
                    sm: "13px",
                    md: "15px",
                  },
                }}
                fontStyle="normal"
                fontWeight="400"
                textAlign="justify">
                <span style={{ fontWeight: "600" }}> Entry level:</span> GNM
                /BSc Nursing / Graduates or Diploma Holders/ GAP Training(for
                experienced nurses)
              </Typography>
            </Grid>
          </Grid>
          <Card className={styles.card}>
            <CardContent>
              {/* <Typography
                fontFamily="Inter"
                fontSize="20px"
                fontWeight="600"
                textAlign="center">
                Competency Training - Certificate Courses
              </Typography> */}
              <ul className={styles.list}>
                {[
                  "Critical Care Nursing",
                  "Emergency Nursing",
                  "Cath Lab Nursing",
                  "OR Nursing",
                  "Infection Control Nursing",
                  "Neonatology Nursing",
                  "Cath Lab Technology",
                ].map((item) => (
                  <li key={item} className={styles.listItem}>
                    {item}
                  </li>
                ))}
              </ul>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <Typography
        className={styles.subheading}
        fontFamily="Inter"
        fontSize="28px"
        sx={{
          fontSize: {
            xs: "23px",
            sm: "23px",
            md: "28px",
          },
        }}
        fontWeight="600"
        marginTop="20px">
        Contact Info
      </Typography>
      <Typography
        fontFamily="Inter"
        fontSize="20px"
        sx={{
          fontSize: {
            xs: "18px",
            sm: "18px",
            md: "20px",
          },
        }}
        fontWeight="500">
        Prof. Gigi Paravila John
      </Typography>
      <Typography
        fontFamily="Inter"
        sx={{
          fontSize: {
            xs: "13px",
            sm: "13px",
            md: "16px",
          },
        }}
        fontWeight="400">
        Chief Administrative Officer, GG Health Academy
      </Typography>
      <Typography
        fontFamily="Inter"
        sx={{
          fontSize: {
            xs: "13px",
            sm: "13px",
            md: "16px",
          },
        }}
        fontWeight="400">
        Phone: 8129435557
      </Typography>
      <Typography
        fontFamily="Inter"
        sx={{
          fontSize: {
            xs: "13px",
            sm: "13px",
            md: "16px",
          },
        }}
        fontWeight="400">
        Email: ggiahs2024@gmail.com
      </Typography>

      <Grid>
        {" "}
        <Typography
          fontFamily="Inter"
          fontWeight="600"
          textAlign="center"
          marginTop="70px"
          sx={{
            fontSize: {
              xs: "18px",
              sm: "18px",
              md: "23px",
            },
          }}>
          ABOUT DR SHEEJA G MANOJ MANAGING DIRECTOR GG HOSPITAL
        </Typography>
        <Typography
          color="var(--dark, #211E1E)"
          fontFamily="Inter"
          marginTop="10px"
          sx={{
            fontSize: {
              xs: "14px",
              sm: "14px",
              md: "16px",
            },
          }}
          fontStyle="normal"
          fontWeight="400"
          textAlign="center">
          Dr. Sheeja G Manoj is the Managing Director of GG Hospital, a Sister
          Concern of Sree Gokulam Medical College and the Deputy Director of
          Sree Gokulam Medical College, Trivandrum. GG Hospital is acclaimed as
          the pioneer corporate destination for common people under Sree Gokulam
          Group. A proponent of integrated healthcare, GG Hospital has a robust
          presence across the healthcare spectrum in the early days of its
          inception under her tremendous leadership.
          <span style={{ fontWeight: "600" }}>
            {" "}
            Dr. Sheeja holds MBBS and MD in Radio-diagnosis. Additionally, she
            is qualified with MBA in Hospital Administration.
          </span>
          <br></br> Spearheading the strategic functions and operations of GG
          Hospital, Greatly inspired by her father and founding Chairman, Sri.
          Gokulam Gopalan, Dr. Sheeja was instrumental in bringing the concept
          of “Corporate health care destination for Common People” first time
          into the healthcare scenario of India and subsequently she is
          facilitating highest standards of health care reliably, safety and
          qualitatively in an advanced and cost effective way. Now, with a clear
          focus in making the organizations future ready, Dr. Sheeja is heading
          efforts to move GG Hospital into a digitally enabled organization.{" "}
          <br></br>Committed to service, Dr. Sheeja provides leadership and
          direction to several social projects and women empowerment forums. For
          the outstanding contribution in the field of women empowerment and
          corporate leadership through health care administration, Dr. Sheeja is
          recognized with{" "}
          <span style={{ fontWeight: "600" }}>
            Rashtriya Nirman Ratan Award in 2019
          </span>
            .
        </Typography>
      </Grid>
    </Container>
  );
};

export default HealthAcademy;
